<template>
  <div
    class="d-flex"
    :class="hasPreviousSection || isFirstSection ? 'justify-content-between' : 'justify-content-end'"
  >
    <ib-navigation-button-back
      v-if="hasPreviousSection || isFirstSection"
      class="idea-score-btn"
      :class="{'mr-1 mr-md-0': hasPreviousSection || isFirstSection}"
      :disabled="disabled"
      :title="`${$t(previousSectionName)}`"
      @click="onBack"
    />
    <ib-navigation-button-next
      class="idea-score-btn"
      :class="{'ml-1 ml-md-0': hasPreviousSection, 'next-btn': !hasPreviousSection && !isFirstSection}"
      :text="nextBtnText"
      :disabled="isDisableNextBtn"
      :title="`${$t(nextSectionName)}`"
      :is-nex-section-market="isNexSectionMarket"
      @click="onNext"
    />
  </div>
</template>

<script>
import IbNavigationButtonBack from '@/components/_v2/Button/IbNavigationButtonBack'
import IbNavigationButtonNext from '@/components/_v2/Button/IbNavigationButtonNext'
import MixinUserSubscription from '@/mixins/permissions/userSubscription'

export default {
  name: 'IdeaScoreTestBottomNavigation',

  components: {
    IbNavigationButtonBack,
    IbNavigationButtonNext
  },

  mixins: [MixinUserSubscription],

  inject: ['getIsFirstSection', 'getIsNexSectionMarket'],

  props: {
    nextSection: {
      type: Object,
      default: null
    },

    previousSection: {
      type: Object,
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    },

    disableNextBtn: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    isDisableNextBtn () {
      return this.disabled || this.disableNextBtn
    },

    hasNextSection () {
      return !!this.nextSection
    },

    hasPreviousSection () {
      return !!this.previousSection
    },

    nextBtnText () {
      return !this.hasNextSection ? this.$t('finish') : null
    },

    nextSectionName () {
      return this.hasNextSection ? this.nextSection.name : ''
    },

    previousSectionName () {
      return this.hasPreviousSection ? this.previousSection.name : ''
    },

    isFirstSection () {
      return this.getIsFirstSection()
    },

    isNexSectionMarket () {
      return !!(this.subscriptionIsFreeTrial && this.getIsNexSectionMarket())
    }
  },

  methods: {
    onBack () {
      if (this.disabled) return

      this.$emit('back')
    },

    onNext () {
      if (this.isDisableNextBtn) return
      if (!this.hasNextSection && !this.previousSection) return this.$emit('final-step')
      if (!this.hasNextSection) return this.$emit('finish')

      this.$emit('next')
    }
  }
}
</script>

<style scoped lang="scss">
.idea-score-btn {
  width: 100%;
  min-width: unset;

  &.next-btn {
    width: unset;
    min-width: 200px;
  }

  @include media-breakpoint-up($md) {
    min-width: 200px;
    width: unset;
  }
}
</style>
